import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "buildings/";
import authHeader from "../services/auth-header";
export const buildings = {
  state: {
    buildings: [],
    building: {},
    namesAll: [],
  },
  mutations: {
    getBuildings(state, buildings) {
      state.buildings = buildings;
    },
    getBuilding(state, building) {
      state.building = building;
    },
    getnamesBuildings(state, name) {
      state.namesAll = name;
    },
    SOCKET_STATUS(state, payload) {
      let data = payload[0];
      if (data.update == "building") {
        const index = state.buildings.findIndex((x) => x.id === data.data._id);
        console.log(index);
        if (index > -1) {
          state.buildings[index] = data.data;
        }
        if (state.building) {
          if (state.building.id == data.data._id) {
            state.building = data.data;
          }
        }
        console.log(state.building);
      }
    },
  },
  getters: {
    Buildings: (state) => state.buildings,
    oneBuilding: (state) => state.building,
    namesBuilding: (state) => state.namesAll,
  },
  actions: {
    async getNames(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          //console.log(res.data.data);
          context.commit("getnamesBuildings", res.data.data);
          context.commit("getBuildings", res.data.data);
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    async getBuilding(context, id) {
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((res) => {
          //console.log(res.data.data[0]);
          context.commit("getBuilding", res.data.data[0]);
          return res.data.data[0];
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    async getBuildings({ commit }, data) {
      return axios
        .post(
          apiURL,
          { lastNumber: data.last, length: data.length },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res);
          if (res.data.data.length > 0) {
            commit("getBuildings", res.data.data);
          }
          return res.data.data;
        })
        .catch((error) => {
          return error.response;
        });
    },
    createBuildings(context, data) {
      return axios
        .post(
          apiURL + "insert",
          {
            buildingName: data.name,
            macAddress: data.macAddress,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res);
          return res;
        })
        .catch((error) => {
          //console.log(error.response);
          return error.response;
        });
    },
    deleteBuildings(context, id) {
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then(() => {
          //console.log("Delete is successfully");
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    editBuildings(constext, data) {
      return axios
        .put(
          apiURL + data.id,
          {
            buildingName: data.name,
            macAddress: data.macAddress,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          return res;
        })
        .catch((error) => {
          //console.log(error.response);
          return error.response;
        });
    },
  },
};
