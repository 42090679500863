<template>
  <div>
    <v-container fluid>
      <v-breadcrumbs :items="itemsRouter">
        <template v-slot:divider>
          <v-icon size="25">mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :disabled="item.disabled" :href="item.href">
            <span :style="`color: ${item.color}`">
              {{ item.text.toUpperCase() }}
            </span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-card id="card" class="mx-6">
        <v-col cols="12">
          <v-card-title>
            <v-row align="center">
              <v-col cols="12" lg="6" md="6" sm="6"> Realtime </v-col>
              <v-col align="end">
                <v-row align="center" justify="end">
                  <v-col cols="12" lg="6" md="6" sm="6">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="dates"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      color="#D32F2F"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="computedDateFormatted"
                          label="Select Date range"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          color="#D32F2F"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dates"
                        range
                        color="#D32F2F"
                        scrollable
                        no-title
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="#D32F2F" @click="cancel()">
                          Cancel
                        </v-btn>
                        <v-btn text color="#D32F2F" @click="save(dates)">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col align="end" cols="12" lg="2" md="2" sm="2">
                    <v-btn
                      color="#D32F2F"
                      fab
                      small
                      dark
                      class="mr-2"
                      @click="searchDate()"
                    >
                      <v-icon> mdi-magnify </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="getLogs"
            :items-per-page="5"
          >
          </v-data-table>
        </v-col>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    getLogs() {
      return this.$store.getters.allLogs;
    },
    computedDateFormatted() {
      return this.formatDate(this.dates);
    },
  },
  data() {
    return {
      menu: false,
      color: null,
      loading: false,
      dates: [],
      itemsRouter: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard",
          color: "red",
        },
        {
          text: "Plant",
          disabled: true,
        },
      ],
      datasearch: {
        id: null,
        type: "",
        eventStart: "",
        eventEnd: "",
      },
      name: null,
      id: null,
      headers: [
        {
          text: "Date",
          sortable: true,
          value: "dateStart",
          width: "15%",
        },
        { text: "Time", value: "timeStart", sortable: true, width: "15%" },
        {
          text: "Description",
          value: "description",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    if (!this.currentUser || this.id == null) {
      this.$router.push("/");
    } else {
      this.loadPage();
      /* this.$options.sockets.status = (data) => {
        if (data.event == true) {
          this.realtime();
        }
      }; */
    }
  },
  created() {
    if (this.$route.params.data != null) {
      this.name = this.$route.params.data.buildingName;
      this.id = this.$route.params.data.id;
      Cookies.set("nameBuliding", this.name);
      Cookies.set("idBuliding", this.id);
    } else {
      this.name = Cookies.get("nameBuliding");
      this.id = Cookies.get("idBuliding");
    }
  },
  methods: {
    realtime() {
      this.loadPage();
    },
    cancel() {
      this.menu = false;
      this.dates = null;
    },
    save(dates) {
      if (dates.length == 1) {
        this.snackbar = true;
        this.color = "error";
        this.text = "Error! Please choose a End Time.";
      } else {
        this.$refs.menu.save(dates);
      }
    },
    loadPage: async function () {
      this.name = Cookies.get("nameBuliding");
      this.id = Cookies.get("idBuliding");
    },
    formatDate(dates) {
      if (!dates) return null;
      const dateFormatted = [];
      for (let i = 0; i < dates.length; i++) {
        dateFormatted[i] = this.parseDate(dates[i]);
      }
      if (dateFormatted.length === 2) {
        const [yearstart, monthstart, daystart] = dateFormatted[0].split("/");
        const [yearend, monthend, dayend] = dateFormatted[1].split("/");
        const data = [dateFormatted[0], dateFormatted[1]];
        if (yearstart > yearend) {
          dateFormatted[0] = data[1];
          dateFormatted[1] = data[0];
        } else if (monthstart > monthend) {
          dateFormatted[0] = data[1];
          dateFormatted[1] = data[0];
        } else if (daystart > dayend) {
          dateFormatted[0] = data[1];
          dateFormatted[1] = data[0];
        }
      }
      return dateFormatted.join(" - ");
    },
    parseDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    searchDate() {
      this.loading = true;
      console.log(this.id)
      this.datasearch.id = this.id;
      if (this.dates != "") {
        if (this.dates.length === 2) {
          const [yearstart, monthstart, daystart] = this.dates[0].split("-");
          const [yearend, monthend, dayend] = this.dates[1].split("-");
          const data = [this.dates[0], this.dates[1]];
          if (yearstart > yearend) {
            this.dates[0] = data[1];
            this.dates[1] = data[0];
          } else if (monthstart > monthend) {
            this.dates[0] = data[1];
            this.dates[1] = data[0];
          } else if (daystart > dayend) {
            this.dates[0] = data[1];
            this.dates[1] = data[0];
          }
          this.datasearch.eventStart = this.parseDate(this.dates[0]);
          this.datasearch.eventEnd = this.parseDate(this.dates[1]);
          this.$store
            .dispatch("getLogs", this.datasearch)
            .then(() => {
              this.color = "success";
              this.text = "Search reports successfully.";
              this.snackbar = true;
              this.loading = false;
            })
            .catch((error) => {
              ////console.log(error);
              this.snackbar = true;
              this.color = "error";
              this.text = "Error! You cannot search reports.";
              this.loading = false;
            });
        } else {
          this.snackbar = true;
          this.color = "error";
          this.text = "Error! Please choose a End Time.";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#card {
  border-top: 5px solid;
  border-top-color: #d32f2f;
}
</style>
