import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "logs/";
import authHeader from "../services/auth-header";
import Cookies from "js-cookie";
export const logs = {
  state: {
    logs: [],
  },
  getters: {
    allLogs: (state) => state.logs,
  },
  mutations: {
    setLogs(state, logs) {
      state.logs = logs;
    },
    SOCKET_STATUS(state, payload) {
      //console.log(payload[0]);
      let data = payload[0];
      let id = Cookies.get("idBuliding");

      if (data.update == "log") {
        if (id == data.data.building) {
          let newArray = [data.data].concat(state.logs);
          //console.log(newArray)
          state.logs = newArray;
        }
      }
      //id
    },
  },
  actions: {
    async getLogs(context, data) {
      console.log(data)
      return axios
        .post(apiURL + "search", data, { headers: authHeader() })
        .then((res) => {
          //console.log(res.data.data);
          context.commit("setLogs", res.data.data);
          return res;
        })
        .catch((error) => {
          //console.log(error);
          return error.response;
        });
    },
  },
};
