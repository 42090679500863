import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "events/";
import authHeader from "../services/auth-header";
import Cookies from "js-cookie";
export const events = {
  state: {
    events: [],
    eventsAll: {},
    eventsBytype: [],
    typeEvents: [],
  },
  getters: {
    eventsBuliding: (state) => state.events,
    eventsBytype: (state) => state.eventsBytype,
    eventsAll: (state) => state.eventsAll,
    typeEvents: (state) => state.typeEvents,
  },
  actions: {
    setClear(context, id) {
      //console.log(id);
      return axios
        .post(apiURL + "clear", { building: id }, { headers: authHeader() })
        .then((res) => {
          return res;
        })
        .catch((error) => {
          //console.log(error);
          return error.response;
        });
    },
    getAllEvents({ commit }, data) {
      //console.log("getEvents");
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          //console.log(res);
          commit("getAllEvents", res.data);
          return res.data;
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    getEvents({ commit }, data) {
      //console.log("getEvents");
      if (data.type == "All") {
        data.type = "";
      }
      //console.log(data)
      return axios
        .post(
          apiURL,
          {
            building: data.id,
            typeSearch: data.type,
            eventStart: data.eventStart,
            eventEnd: data.eventEnd,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res.data.data);
          commit("getEvents", res.data.data);
          if (data.type == "") {
            commit("typeEvents", res.data.data);
          }
          return res.data.data;
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    getEventsType({ commit }, data) {
      if (data.type == "All") {
        data.type = "";
      }
      //console.log("getEvents");
      return axios
        .post(
          apiURL,
          {
            building: data.id,
            typeSearch: data.type,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res.data.data);
          commit("getEventsType", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
  },
  mutations: {
    getEvents(state, events) {
      state.events = events;
    },
    getAllEvents(state, events) {
      state.eventsAll = events;
    },
    getEventsType(state, eventsBytype) {
      state.eventsBytype = eventsBytype;
    },
    typeEvents(state, data) {
      state.typeEvents = data.type;
    },
    SOCKET_STATUS(state, payload) {
      let data = payload[0];
      const user = this.state.auth.access;

      if (data.update == "report") {
        if (
          user.buildingName == data.data.building.buildingName ||
          user.role == "Superadmin"
        ) {
          let newArray = [data.data].concat(state.eventsAll.data);
          //console.log(newArray)
          state.eventsAll = {
            data: newArray,
            count: state.eventsAll.count + 1,
          };
        }
        if (this.state.buildings.building) {
          if (
            this.state.buildings.building.buildingName ==
            data.data.building.buildingName
          ) {
            let newArray = [data.data].concat(state.events.event);
            //console.log(state.events);
            state.events.event = newArray;
            const index = state.events.type.findIndex(
              (x) => x.name === data.data.type
            );
            if (index > -1) {
              //console.log(state.events.type[index].num);
              state.events.type[index].num = state.events.type[index].num + 1;
            }
          }
        }
      }
    },
  },
};
