import axios from "axios";
const apiURL = process.env.VUE_APP_API_URL + "reports/";
import authHeader from "../services/auth-header";
import Cookies from "js-cookie";
export const reports = {
  state: {
    reports: [],
    report: [],
    reportBytype: [],
    typeReports: [],
  },
  getters: {
    reportsBuliding: (state) => state.reports,
    reportBuliding: (state) => state.report,
    reportBytype: (state) => state.reportBytype,
    typeReports: (state) => state.typeReports,
  },
  actions: {
    getReports({ commit }) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          //console.log(res.data.data.type);
          commit("getReports", res.data.data);
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    getReportByID({ commit }, data) {
      if (data.type == "All") {
        data.type = "";
      }
      return axios
        .post(
          apiURL,
          {
            building: data.id,
            typeSearch: data.type,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res.data.data);
          commit("getReport", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    getReportByIDType({ commit }, data) {
      if (data.type == "All") {
        data.type = "";
      }
      return axios
        .post(
          apiURL,
          {
            building: data.id,
            typeSearch: data.type,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          //console.log(res.data.data);
          commit("getReportByType", res.data.data);
          return res.data.data;
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
    getReportBySearch({ commit }, data) {
      console.log(data);

      return axios
        .post(
          apiURL + "search",
          {
            building: data.id,
            typeSearch: data.type,
            eventStart: data.eventStart,
            eventEnd: data.eventEnd,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          commit("getReportBySearch", res.data.data.report);
          if (data.type == "") {
            commit("typeReports", res.data.data);
          }
        })
        .catch((error) => {
          //console.log(error);
          return error;
        });
    },
  },
  mutations: {
    getReport(state, report) {
      state.report = report;
    },
    getReportByType(state, reportBytype) {
      state.reportBytype = reportBytype;
    },
    getReportBySearch(state, report) {
      state.reports = report;
    },
    getReports(state, report) {
      state.reports = report;
    },
    typeReports(state, data) {
      console.log(data.type);
      state.typeReports = data.type;
    },
    SOCKET_STATUS(state, payload) {
      //console.log(payload[0]);
      const data = payload[0];

      //id
      if (this.state.buildings.building) {
        if (this.state.buildings.building.buildingName) {
          if (
            this.state.buildings.building.buildingName ==
            data.data.building.buildingName
          ) {
            console.log(state.reports);
            let newArray = [data.data].concat(state.reports);
            //console.log(newArray);
            state.reports = newArray;
          }
        }
      }
    },
  },
};
