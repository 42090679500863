<template>
  <div v-resize="onResize" v-if="getAccess">
    <div v-if="!isApp">
      <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        color="#D32F2F"
        dense
        dark
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>
          <span class="hidden-sm-and-down text-h6">
            Fire Alarm Monitoring
          </span>
        </v-toolbar-title>
        <!-- <v-btn text @click="changePageHome()"> Fire Alarm Monitoring </v-btn> -->
        <v-spacer></v-spacer>
        <v-btn text @click="changePageHome()">HOME</v-btn>
        <v-menu :nudge-width="200" offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <notification-bell
                :size="20"
                :count="getAllEvent.count"
                :upperLimit="200"
                :ding="ding"
                :animated="true"
                counterLocation="upperRight"
                counterStyle="roundRectangle"
                counterBackgroundColor="#000"
                counterTextColor="#FFFFFF"
                iconColor="#fff"
              />
            </v-btn>
          </template>
          <v-card width="450" v-if="getAllEvent.count != 0">
            <v-list style="max-height: 400px; overflow-y: auto">
              <v-list-item v-for="(item, i) in getAllEvent.data" :key="i">
                <v-list-item-content>
                  <b
                    >{{ item.dateStart }} {{ item.timeStart }} <br />
                    Plant : {{ item.building.buildingName }}
                  </b>

                  <span>
                    {{ item.description }}
                  </span>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-row>
                  <v-col>
                    <v-btn
                      text
                      block
                      v-if="getAccess.role !== 'Superadmin'"
                      :to="{
                        name: 'Events',
                        params: { id: getAccess.building },
                      }"
                    >
                      Show All
                    </v-btn>
                    <v-btn
                      v-for="item in getBuildings"
                      text
                      block
                      v-if="getAccess.role == 'Superadmin'"
                      :to="{ name: 'Events', params: { id: item.id } }"
                    >
                      Show all {{ item.buildingName }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-card>
          <v-card width="450" class="text-center" v-else>
            <v-list style="max-height: 400px; overflow-y: auto">
              <v-list-item>
                <v-list-item-content>
                  <span> No Notifications </span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
          </v-card>
        </v-menu>
        <v-menu left bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon style="font-size: 22px">mdi-account</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon style="font-size: 22px">mdi-account</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title> {{ getAccess.name }} </v-list-item-title>
                  <v-list-item-subtitle>
                    @{{ getAccess.username }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>
            <v-list>
              <v-list-item @click="openDialog()">
                <v-list-item-action>
                  <v-icon>mdi-key-variant</v-icon>
                </v-list-item-action>
                <v-list-item-title>Change password</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-action>
                  <v-icon>mdi-logout-variant</v-icon>
                </v-list-item-action>
                <v-list-item-title> Logout </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-app-bar>
      <v-navigation-drawer
        class="black"
        v-model="drawer"
        :clipped="$vuetify.breakpoint.lgAndUp"
        width="200"
        dark
        app
      >
        <v-list dense nav>
          <v-list-item
            v-if="this.items === this.buildingManu"
            @click="events()"
          >
            <v-list-item-action>
              <v-icon style="font-size: 22px" color="#D32F2F"
                >mdi-bell-alert</v-icon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Events</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            router
            :to="item.route"
          >
            <v-list-item-icon v-if="item.title == 'Line Setting'">
              <font-awesome-icon
                icon="fa-brands fa-line fa-lg"
                color="red"
                style="font-size: 22px"
                class="ml-1"
              />
            </v-list-item-icon>
            <v-list-item-icon v-else>
              <v-icon style="font-size: 22px" color="#D32F2F">{{
                item.icon
              }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="getAccess.role === 'Superadmin' && homePath"
            @click="changePageAdmin()"
          >
            <v-list-item-action>
              <v-icon style="font-size: 22px" color="#D32F2F">mdi-cog</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Setting Admin</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="
              getAccess.role === 'Superadmin' &&
              getAccess.username === 'pmAdmin' &&
              this.items === this.adminManu
            "
            @click="plants()"
          >
            <v-list-item-action>
              <v-icon style="font-size: 22px" color="#D32F2F"
                >mdi-factory</v-icon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Plants</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="
              getAccess.role === 'Superadmin' &&
              this.items === this.buildingManu
            "
            @click="words()"
          >
            <v-list-item-action>
              <v-icon style="font-size: 22px" color="#D32F2F"
                >mdi-form-textbox</v-icon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Key Word Setting</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>

    <div v-else>
      <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        color="#D32F2F"
        dense
        dark
      >
        <v-toolbar-title>
          <span class="text-h6"> Fire Alarm Monitoring </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu left bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title> {{ getAccess.name }} </v-list-item-title>
                  <v-list-item-subtitle>
                    @{{ getAccess.username }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list>
              <v-list-item @click="openDialog()">
                <v-list-item-action>
                  <v-icon>mdi-key-variant</v-icon>
                </v-list-item-action>
                <v-list-item-title>Change password</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-action>
                  <v-icon>mdi-logout-variant</v-icon>
                </v-list-item-action>
                <v-list-item-title> Logout </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-app-bar>
    </div>
    <simplert />
    <v-dialog v-model="dialog" max-width="350">
      <v-card>
        <v-card-title class="headline red darken-2 white--text">
          Change password
        </v-card-title>
        <v-card-text>
          <v-form
            @submit.prevent="changePassword()"
            @keyup.native.enter="changePassword()"
            ref="changPassword"
            lazy-validation
            enctype="multipart/form-data"
            autocomplete="off"
          >
            <v-text-field
              :type="password.hideOld ? 'text' : 'password'"
              :append-icon="
                password.hideOld ? 'mdi-eye-outline' : 'mdi-eye-off'
              "
              name="old password"
              label="Old Password"
              hide-details="auto"
              v-model="password.old"
              @click:append="password.hideOld = !password.hideOld"
              :rules="[(v) => !!v || 'Old Password is required']"
              required
              @keypress="isLetter($event)"
              color="#000"
            >
            </v-text-field>
            <v-text-field
              :type="password.hideNew ? 'text' : 'password'"
              :append-icon="
                password.hideNew ? 'mdi-eye-outline' : 'mdi-eye-off'
              "
              name="new password"
              label="New Password"
              hide-details="auto"
              v-model="password.new"
              @click:append="password.hideNew = !password.hideNew"
              :rules="[(v) => !!v || 'New Password is required']"
              required
              @keypress="isLetter($event)"
              color="#000"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="success" text @click="changePassword()">Change</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" bottom left :color="color" timeout="1500">
      {{ text }}
      <v-btn color="white" text @click="snackbar = false"> close </v-btn>
    </v-snackbar>
    <notifications
      group="custom-template"
      position="bottom right"
      closeOnClick
      :max="1"
      :width="300"
      animation-type="velocity"
    >
      <template slot="body" slot-scope="props">
        <div class="ma-2">
          <v-card max-width="300" color="#D32F2F" dark>
            <v-card-text>
              <v-row align="center">
                <v-col cols="10" justify="center" class="mb-0 pb-0">
                  <p class="text-h7 text--white">
                    <b>
                      {{ props.item.title }} <br />Plant:
                      {{ props.item.data.buildingName }}
                    </b>
                  </p>
                </v-col>
                <!-- <v-col cols="2" class="mt-0 pt-0">
                  <v-btn icon @click="deleteNoti(props.item)">
                    <v-icon color="white">mdi-close-circle</v-icon>
                  </v-btn>
                </v-col> -->
              </v-row>
              <v-row>
                <div class="col-12 ma-0 text-truncate text-h7 text--white">
                  {{ props.item.data.description }}
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </template>
    </notifications>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import NotificationBell from "vue-notification-bell";
export default {
  components: {
    NotificationBell, // Registering Component
  },
  computed: {
    getAccess() {
      return this.$store.state.auth.access;
    },
    getBuildings() {
      return this.$store.getters.Buildings;
    },
    getNotify() {
      return this.$store.getters.allNoti;
    },
    getAllEvent() {
      //console.log(this.$store.getters.eventsAll);
      return this.$store.getters.eventsAll;
    },
  },
  created() {
    this.$store.dispatch("auth/decode");
    if (this.getAccess != undefined) {
      this.checkManu();
      this.$store.dispatch("getAllEvents");
    } else {
      this.$router.push("/login");
    }
  },
  watch: {
    $route(to, from) {
      //console.log(to.name)
      if (to) {
        this.$Simplert.close();
      }
      if (
        to.name.search("Accounts") === 0 ||
        to.name.search("LineConfig") === 0 ||
        to.name.search("Plants") === 0
      ) {
        this.items = this.adminManu;
        this.homePath = false;
      } else {
        this.items = this.homeManu;
        this.homePath = true;
      }
      if (
        to.name.search("Events") === 0 ||
        to.name.search("SetMessages") === 0 ||
        to.name.search("RealTime") === 0 ||
        to.name.search("SetWords") === 0
      ) {
        this.items = this.buildingManu;
        this.homePath = false;
      }
    },
  },
  data() {
    return {
      idBuliding: null,
      noti: [],
      ding: false,
      closeOnClick: true,
      alert: false,
      vertical: true,
      isMobile: false,
      isApp: false,
      dialog: false,
      drawer: null,
      path: null,
      items: null,
      homePath: true,
      password: {
        old: "",
        new: "",
        hideOld: false,
        hideNew: false,
      },
      payload: {
        id: "",
        newPassword: "",
        password: "",
      },
      snackbar: false,
      text: "",
      color: "",
      homeManu: [
        { title: "Dashboard", icon: "mdi-view-dashboard", route: "/dashboard" },
        { title: "Reports", icon: "mdi-file-document", route: "/reports" },
      ],
      adminManu: [
        { title: "Accounts", icon: "mdi-account", route: "/accounts" },
        { title: "Line Setting", icon: "fa-line", route: "/lineConfig" },
      ],
      buildingManu: [
        {
          title: "Realtime ",
          icon: "mdi-message-text-clock",
          route: "/plant/realtime",
        },
        {
          title: "Messages Setting ",
          icon: "mdi-message-draw",
          route: "/plant/setMessages",
        },
      ],
    };
  },
  mounted() {
    //console.log(this.getAccess)
    const isInStandaloneMode = () =>
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone ||
      document.referrer.includes("android-app://");
    if (isInStandaloneMode()) {
      //console.log("webapp is installed");
      this.isApp = true;
    }
    this.idBuliding = Cookies.get("idBuliding");
    this.$options.sockets.status = (data) => {
      console.log(data);
      if (data.building != undefined) {
        this.realtime(data);
      }
    };
  },
  methods: {
    /* deleteNoti(itme) {
      //console.log(itme);
      let id;
      if (itme.data != undefined) {
        id = itme.data._id;
      } else {
        id = itme._id;
      }
      //console.log(id);
      const i = this.getNotify.findIndex((noti) => noti._id == id);
      this.getNotify.splice(i, 1);
      this.$store.dispatch("deleteNoti", id).then(
        (res) => {
          //console.log(res);
          this.$notify.close(itme.id);
          this.color = "success";
          this.text = "Success! Your notification has been delete!";
          this.snackbar = true;
        },
        () => {
          this.text = "Error! Your old password is incorrect!";
          this.color = "red";
          this.snackbar = true;
          this.password.old = "";
        }
      );
    }, */
    realtime(data) {
      console.log(data);
      if (
        this.getAccess.role == "Superadmin" ||
        this.getAccess.building == data.building
      ) {
        this.ding = true;
        this.$notify({
          group: "custom-template",
          title: `${data.dateStart} ${data.timeStart}`,
          text: `${data.description}`,
          duration: 2000,
          type: "warn",
          data: data,
          animation: {
            enter: { opacity: [0, 0] },
            leave: { opacity: [0, 0] },
          },
          // (optional, override)
          // Time (in ms) to show / hide notifications
          speed: 10,
        });
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    onResize() {
      if (window.innerWidth < 960) this.isMobile = true;
      else this.isMobile = false;
    },
    openDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.password.new = "";
      this.password.old = "";
    },
    changePassword() {
      this.payload.id = this.getAccess.id;
      this.payload.newPassword = this.password.new;
      this.payload.password = this.password.old;
      if (this.password.new == "" || this.password.old == "") {
        this.text = "Error! please fill out this form";
        this.color = "red";
        this.snackbar = true;
      } else if (
        /^[A-Za-z0-9]+$/.test(this.password.old) &&
        /^[A-Za-z0-9]+$/.test(this.password.new)
      ) {
        this.$store.dispatch("auth/changePassword", this.payload).then(
          (res) => {
            //console.log(res);
            this.dialog = false;
            this.color = "success";
            this.text = "Success! Your Password has been changed!";
            this.snackbar = true;
            this.close();
          },
          () => {
            this.text = "Error! Your old password is incorrect!";
            this.color = "red";
            this.snackbar = true;
            this.password.old = "";
          }
        );
      } else {
        this.text = "Error! Password can only use A-Z a-z 0-9";
        this.color = "red";
        this.snackbar = true;
      }
    },
    checkManu() {
      this.path = this.$router.currentRoute.name;
      //console.log(this.path);
      if (
        this.path.search("Accounts") === 0 ||
        this.path.search("LineConfig") === 0 ||
        this.path.search("Plants") === 0
      ) {
        this.items = this.adminManu;
        this.homePath = false;
      } else {
        this.items = this.homeManu;
        this.homePath = true;
      }
      if (
        this.path.search("Events") === 0 ||
        this.path.search("SetMessages") === 0 ||
        this.path.search("SetWords") === 0 ||
        this.path.search("RealTime") === 0
      ) {
        //console.log("dddd")
        this.items = this.buildingManu;
        this.homePath = false;
      }
    },
    changePageHome() {
      this.$router.push("/dashboard");
      this.checkManu();
    },
    changePageAdmin() {
      this.$router.push("/accounts");
      this.checkManu();
    },
    plants() {
      this.$router.push("/plants");
    },
    words() {
      this.$router.push("/plant/setWords");
    },
    events() {
      this.$router.push({ name: "Events", params: { id: this.idBuliding } });
    },
    logout() {
      let confirmFn = function () {
        try {
          this.$store.dispatch("auth/logout").then(() => {
            this.$router.push("/");
          });
        } catch (error) {
          //console.log(error);
        }
      };
      let obj = {
        message:
          '<span style="font-size: 180%; font-weight: bold;">You want to log out?</span>',
        type: "warning",
        useConfirmBtn: true,
        customConfirmBtnText: "  Yes  ",
        customCloseBtnText: "Cancel",
        customConfirmBtnClass:
          "v-btn v-btn--has-bg theme--light v-size--default primary mx-4 same",
        customCloseBtnClass:
          "v-btn v-btn--has-bg theme--light v-size--default error same",
        onConfirm: confirmFn,
      };
      this.$Simplert.open(obj);
    },
  },
};
</script>
<style lang="scss">
.wrap-text {
  -webkit-line-clamp: unset !important;
}
.no-procced-btn {
  width: 100px;
}
.same {
  width: 85px;
}
.vue-notification {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  margin: 0 5px 5px;
  padding: 10px;
  font-size: 18px;
  color: #ffffff;

  background: #44a4fc;
  border-left: 5px solid #187fe7;

  &.success {
    background: #68cd86;
    border-left-color: #42a85f;
  }

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    background: #e54d42;
    border-left-color: #b82e24;
  }
}
</style>
