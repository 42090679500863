<template>
  <div v-resize="onResize" v-if="getEvents.type != undefined && getMessages">
    <v-container fluid>
      <v-breadcrumbs :items="itemsRouter">
        <template v-slot:divider>
          <v-icon size="25">mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :disabled="item.disabled" :href="item.href">
            <span :style="`color: ${item.color}`">
              {{ item.text.toUpperCase() }}
            </span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-card id="card" class="mx-6">
        <v-row align="center" justify="center">
          <v-col cols="3" lg="2" md="2" class="mt-3">
            <v-img
              :src="require('/src/assets/nohmi.jpg')"
              class="ml-3"
              max-height="150"
              max-width="200"
              contain
            ></v-img>
          </v-col>
          <v-col cols="8" lg="5" md="9" class="pl-0">
            <v-row align="center">
              <v-col
                cols="4"
                xl="2"
                lg="2"
                md="2"
                sm="3"
                class="text-center"
                v-if="this.getBuilding.bell"
              >
                <v-icon x-large color="red darken-2"> mdi-alarm-bell </v-icon>
                <h4>
                  Bell<br />
                  Active
                </h4>
              </v-col>
              <v-col>
                <v-row align="center" class="mt-1">
                  <v-col cols="6" xl="3" lg="4" md="4" sm="4">
                    <h2 class="font-weight-bold black--text">
                      {{ this.name }}
                    </h2>
                  </v-col>
                </v-row>
                <v-row
                  class="mb-0 pb-0"
                  align="center"
                  justify="center"
                  v-if="this.history == false"
                >
                  <v-row
                    v-for="(item, index) in showEvent"
                    :key="index"
                    no-gutters
                    justify="center"
                  >
                    <v-col align-self="center" align="center">
                      <h4 class="justify-center">
                        {{ item.num }}
                      </h4>
                      <h5
                        v-if="item.num > 0"
                        class="justify-center mt-2"
                        :style="showColor(item.index)"
                      >
                        {{ item.name }}
                      </h5>
                    </v-col>
                  </v-row>
                </v-row>
                <v-row class="mb-0 pb-0" align="center" justify="center" v-else>
                  <v-row
                    v-for="(item, index) in showHistory"
                    :key="index"
                    no-gutters
                    justify="center"
                  >
                    <v-col align-self="center" align="center">
                      <h4 class="d-flex flex-no-wrap justify-center">
                        {{ item.num }}
                      </h4>
                      <h5
                        v-if="item.num > 0"
                        class="d-flex flex-no-wrap justify-center mt-2"
                        :style="showColor(item.index)"
                      >
                        {{ item.name }}
                      </h5>
                    </v-col>
                  </v-row>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="5">
            <v-row justify="center" align="center">
              <div
                class="pa-1 mx-2 text-center grey lighten-2 text-no-wrap rounded"
              >
                <v-icon
                  :color="checkStatus(getBuilding.acknowledge)"
                  style="font-size: 30px"
                  >mdi-minus-thick</v-icon
                >
                <h5
                  class="text-uppercase px-1 mt-n1"
                  style="letter-spacing: -1.5px"
                >
                  acknowledge
                </h5>
                <v-btn
                  small
                  color="#F5F5F5"
                  class="white--text flex-no-wrap justify-center mt-1 mx-0 mb-2"
                  style="padding: 15px 10px"
                  @click="setAcknowledge()"
                >
                </v-btn>
              </div>
              <div
                class="py-1 px-3 mx-2 text-center text-no-wrap rounded grey lighten-2"
              >
                <v-icon
                  :color="checkStatus(getBuilding.reset)"
                  style="font-size: 30px"
                  >mdi-minus-thick</v-icon
                >
                <h5 class="px-1 mt-n1">RESET</h5>
                <v-btn
                  small
                  color="#F5F5F5"
                  class="white--text justify-center mt-1 mx-2 mb-2"
                  @click="setReset()"
                  style="padding: 15px 10px"
                >
                </v-btn>
              </div>
              <div
                class="py-1 px-3 mx-2 text-center grey lighten-2 text-no-wrap rounded"
              >
                <v-icon
                  :color="checkStatus(getBuilding.silence)"
                  style="font-size: 30px"
                  >mdi-minus-thick</v-icon
                >
                <h5 class="text-uppercase px-1 mt-n1">silence</h5>
                <v-btn
                  small
                  color="#F5F5F5"
                  class="text-center white--text justify-center mt-1 mx-2 mb-2"
                  style="padding: 15px 10px"
                  @click="setSilence()"
                >
                </v-btn>
              </div>
            </v-row>
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-row>
            <v-col lg="10" md="8" sm="12">
              <v-tabs
                v-model="tab"
                background-color="transparent"
                color="red"
                grow
              >
                <v-tab @click="sumevents()">
                  <v-icon left>mdi-bell-ring</v-icon> system status
                </v-tab>
                <v-tab @click="sumhistorys()">
                  <v-icon left>mdi-history</v-icon>event log
                </v-tab>
              </v-tabs>
            </v-col>
            <v-col
              lg="2"
              md="4"
              sm="12"
              align="end"
              v-if="this.history == false && !isMobile"
            >
              <v-btn
                @click="clear()"
                small
                color="#00A0B0"
                class="white--text mx-5 my-2"
              >
                clear
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            class="mb-0 pb-0"
            align="center"
            justify="start"
            v-if="this.history == false"
          >
            <v-col cols="12" lg="3" md="3" sm="12">
              <v-select
                label="Select Type"
                :items="getTypeEvent"
                v-model="dataSearch.type"
                color="red"
                hide-details="true"
                dense
                outlined
                item-text="name"
                item-value="name"
                style="width: 200px; display: inline-flex; font-size: 11px"
              >
              </v-select>
            </v-col>
            <v-col cols="12" lg="5" md="5" sm="6">
              <v-menu
                ref="menu"
                v-model="menuEvent"
                :close-on-content-click="false"
                :return-value.sync="datesEvent"
                transition="scale-transition"
                offset-y
                min-width="auto"
                color="#D32F2F"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateFormattedEvent"
                    label="Select Date range"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    color="#D32F2F"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="datesEvent"
                  range
                  color="#D32F2F"
                  scrollable
                  no-title
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="#D32F2F" @click="cancelEvent()">
                    Cancel
                  </v-btn>
                  <v-btn text color="#D32F2F" @click="save(datesEvent)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col align="end" cols="12" lg="2" md="2" sm="2">
              <v-tooltip bottom v-if="!isMobile">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#D32F2F"
                    fab
                    small
                    dark
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="searchTypeEvent()"
                  >
                    <v-icon> mdi-magnify </v-icon>
                  </v-btn>
                </template>
                <span>Search</span>
              </v-tooltip>
              <v-btn
                v-else
                color="#D32F2F"
                fab
                small
                dark
                class="mr-2"
                @click="searchTypeEvent()"
              >
                <v-icon> mdi-magnify </v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="4"
              lg="2"
              md="4"
              sm="2"
              align="end"
              v-if="this.history == false && isMobile"
            >
              <v-btn
                @click="clear()"
                small
                color="#00A0B0"
                class="white--text mx-5 my-2"
              >
                clear
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mb-0 pb-0" align="center" justify="start" v-else>
            <v-col cols="12" lg="3" md="4" sm="4">
              <v-select
                label="Select Type"
                :items="getTypeHis"
                v-model="dataSearch.type"
                color="red"
                hide-details="true"
                dense
                outlined
                item-text="name"
                item-value="name"
                style="width: 200px; display: inline-flex; font-size: 11px"
              >
              </v-select>
            </v-col>
            <v-col cols="12" lg="5" md="5" sm="6">
              <v-menu
                ref="menu"
                v-model="menuHis"
                :close-on-content-click="false"
                :return-value.sync="datesHis"
                transition="scale-transition"
                offset-y
                min-width="auto"
                color="#D32F2F"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateFormattedHis"
                    label="Select Date range"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    color="#D32F2F"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="datesHis"
                  range
                  color="#D32F2F"
                  scrollable
                  no-title
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="#D32F2F" @click="cancelHis()">
                    Cancel
                  </v-btn>
                  <v-btn text color="#D32F2F" @click="save(datesHis)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col align="end" cols="12" lg="2" md="2" sm="2">
              <v-tooltip bottom v-if="!isMobile">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#D32F2F"
                    fab
                    small
                    dark
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="searchTypeHis()"
                  >
                    <v-icon> mdi-magnify </v-icon>
                  </v-btn>
                </template>
                <span>Search</span>
              </v-tooltip>
              <v-btn
                v-else
                color="#D32F2F"
                fab
                small
                dark
                class="mr-2"
                @click="searchTypeHis()"
              >
                <v-icon> mdi-magnify </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-layout column>
                <v-data-table
                  :headers="headers"
                  :items="getEvents.event"
                  :hide-default-header="isMobile"
                  item-key="id"
                  :items-per-page="5"
                >
                  <template #item="{ item }">
                    <tr v-if="!isMobile">
                      <td width="10%" :style="showBorder(item.type)">
                        {{ item.dateStart }}
                      </td>
                      <td width="6%">
                        {{ item.timeStart }}
                      </td>
                      <td width="14%">
                        {{ item.type }}
                      </td>
                      <td width="10%">
                        {{ item.deviceNo }}
                      </td>
                      <td width="35%">
                        {{ item.description }}
                      </td>
                    </tr>
                    <tr v-else>
                      <td :style="showBorder(item.type)" class="col-12">
                        <div class="my-2"><b>Date</b> {{ item.dateStart }}</div>
                        <div class="my-2"><b>Time</b> {{ item.timeStart }}</div>
                        <div class="my-2"><b>Type</b> {{ item.type }}</div>
                        <div class="my-2">
                          <b>Device</b> {{ item.deviceNo }}
                        </div>
                        <div class="my-2">
                          <b>Description</b> {{ item.description }}
                        </div>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-layout>
            </v-tab-item>
            <v-tab-item>
              <v-data-table
                :headers="Historyheaders"
                :items="getHistorys"
                :items-per-page="5"
              >
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-card>
      <v-snackbar
        v-model="dataSnack.showResult"
        :color="dataSnack.color"
        buttom
        left
        timeout="2000"
      >
        {{ dataSnack.result }}
        <v-btn color="white" text @click="snackbar = false"> close </v-btn>
      </v-snackbar>
      <v-snackbar v-model="snackbar" buttom left :color="color" timeout="1500">
        {{ text }}
        <v-btn color="white" text @click="snackbar = false"> close </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import config from "@/config";
export default {
  computed: {
    showEvent() {
      let type = this.$store.getters.typeEvents;
      let messages = this.$store.getters.allMessages;
      let show = [];
      for (let i = 0; i < type.length; i++) {
        for (let n = 0; n < messages.length; n++) {
          if (messages[n].message === type[i].name) {
            let data = type[i];
            data.index = i;
            //console.log(data);
            show.push(data);
          }
        }
      }
      //console.log(show);
      return show;
    },
    showHistory() {
      let type = this.$store.getters.typeReports;
      let messages = this.$store.getters.allMessages;
      let show = [];
      for (let i = 0; i < type.length; i++) {
        for (let n = 0; n < messages.length; n++) {
          if (messages[n].message === type[i].name) {
            let data = type[i];
            data.index = i;
            //console.log(data);
            show.push(data);
          }
        }
      }
      return show;
    },
    getTypeHis() {
      let newFirstElement = { name: "All" };
      let array = this.$store.getters.typeReports;
      let newArray = [newFirstElement].concat(array); // [ 4, 3, 2, 1 ]

      return newArray;
    },
    getTypeEvent() {
      let newFirstElement = { name: "All" };
      let array = this.$store.getters.typeEvents;
      let newArray = [newFirstElement].concat(array);
      //console.log(newArray);
      return newArray;
    },
    getMessages() {
      return this.$store.getters.allMessages;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    getEvents() {
      return this.$store.getters.eventsBuliding;
    },
    getHistorys() {
      return this.$store.getters.reportsBuliding;
    },
    getBuilding() {
      return this.$store.getters.oneBuilding;
    },
    computedDateFormattedHis() {
      return this.formatDate(this.datesHis);
    },
    computedDateFormattedEvent() {
      return this.formatDate(this.datesEvent);
    },
  },
  data() {
    return {
      menuHis: false,
      menuEvent: false,
      datesEvent: [],
      datesHis: [],
      snackbar: false,
      text: "",
      color: "",
      itemsRouter: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/dashboard",
          color: "red",
        },
        {
          text: "Plant",
          disabled: true,
        },
      ],
      dataSearch: {
        id: null,
        type: "",
        eventStart: "",
        eventEnd: "",
      },
      dataEdit: {
        id: null,
        name: null,
        status: null,
      },
      dataSnack: {
        showResult: false,
        result: null,
        color: null,
      },
      name: null,
      id: null,
      type: "",
      tab: null,
      isMobile: false,
      history: false,
      items: ["event", "history"],
      text: "Lorem",
      Historyheaders: [
        {
          text: "Date",
          sortable: true,
          value: "dateStart",
          width: "10%",
        },
        { text: "Time", value: "timeStart", sortable: true, width: "6%" },
        { text: "Type", value: "type", sortable: false, width: "10%" },
        { text: "Device", value: "deviceNo", sortable: false, width: "10%" },
        {
          text: "Description",
          value: "description",
          sortable: false,
          width: "35%",
        },
      ],
      headers: [
        {
          text: "Date",
          sortable: true,
          value: "dateStart",
        },
        { text: "Time", value: "timeStart", sortable: true },
        { text: "Type", value: "type", sortable: false },
        { text: "Device", value: "deviceNo", sortable: false },
        {
          text: "Description",
          value: "description",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    //console.log(this.getAccess)
    if (
      !this.currentUser ||
      (this.getAccess.role != "Superadmin" &&
        this.getAccess.building != this.id)
    ) {
      this.$router.push("/");
      Cookies.set("idBuliding", this.getAccess.building);
    } else {
      this.loadPage();
      /* this.$options.sockets.status = (data) => {
        console.log(data);
        if (data.event == true) {
          this.realtime();
        }
      }; */
    }
  },
  created() {
    //console.log(this.getAccess);
    if (this.currentUser != null) {
      if (this.$route.params.id != null) {
        this.id = this.$route.params.id;
        Cookies.set("idBuliding", this.id);
        this.$store.dispatch("getMessages", this.id);
      } else {
        this.id = Cookies.get("idBuliding");
        this.$store.dispatch("getMessages", this.id);
      }
    }
  },
  methods: {
    cancelHis() {
      this.menuHis = false;
      this.datesHis = null;
    },
    cancelEvent() {
      this.menuEvent = false;
      this.datesEvent = null;
    },
    save(dates) {
      if (dates.length == 1) {
        this.snackbar = true;
        this.color = "error";
        this.text = "Error! Please choose a End Time.";
      } else {
        this.$refs.menu.save(dates);
      }
    },
    realtime() {
      this.loadPage();
    },
    checkStatus(status) {
      if (status == true) {
        return "red darken-2";
      } else {
        return "grey";
      }
    },
    setSilence() {
      let dataSnack = this.dataSnack;
      let dataEdit = this.dataEdit;
      dataEdit.id = Cookies.get("idBuliding");
      let confirmFn = function () {
        dataEdit.status = true;
        try {
          //console.log(this.id);
          this.$store
            .dispatch("setSilence", dataEdit)
            .then((res) => {
              if (res.status == 400) {
                dataSnack.showResult = true;
                dataSnack.color = "error";
                dataSnack.result = res.data.error.message;
              } else {
                //console.log("ddd");
                dataSnack.color = "success";
                dataSnack.showResult = true;
                dataSnack.result = "Set silence is success.";
              }
            })
            .catch((error) => {
              //console.log(error);
              dataSnack.showResult = true;
              dataSnack.color = "error";
              dataSnack.result = "Error! You cannot set silence.";
            });
        } catch (error) {
          dataSnack.showResult = true;
          dataSnack.color = "error";
          dataSnack.result = "Error! You cannot set silence.";
        }
      };
      let closeFn = function () {
        dataEdit.status = false;
        try {
          //console.log(this.id);
          this.$store
            .dispatch("setSilence", dataEdit)
            .then((res) => {
              if (res.status == 400) {
                dataSnack.showResult = true;
                dataSnack.color = "error";
                dataSnack.result = res.data.error.message;
              } else {
                //console.log("ddd");
                dataSnack.color = "success";
                dataSnack.showResult = true;
                dataSnack.result = "Set silence is success.";
              }
            })
            .catch((error) => {
              //console.log(error);
              dataSnack.showResult = true;
              dataSnack.color = "error";
              dataSnack.result = "Error! You cannot set silence.";
            });
        } catch (error) {
          dataSnack.showResult = true;
          dataSnack.color = "error";
          dataSnack.result = "Error! You cannot set silence.";
        }
      };
      let data = {
        text: "silence",
        status: this.getBuilding.silence,
      };
      let obj = {
        message: this.checkOnoff(data),
        type: "warning",
        customClass: "custom-simplert",
        useConfirmBtn: true,
        customConfirmBtnText: "ON",
        customCloseBtnText: "OFF",
        customConfirmBtnClass:
          "v-btn v-btn--has-bg theme--light v-size--default success mx-4 same",
        customCloseBtnClass:
          "v-btn v-btn--has-bg theme--light v-size--default error same",
        onConfirm: confirmFn,
        onClose: closeFn,
        showXclose: true,
      };
      this.$Simplert.open(obj);
    },
    setAcknowledge() {
      let dataSnack = this.dataSnack;
      let dataEdit = this.dataEdit;
      dataEdit.id = Cookies.get("idBuliding");
      let confirmFn = function () {
        dataEdit.status = true;
        try {
          this.$store
            .dispatch("setAcknowledge", dataEdit)
            .then((res) => {
              dataSnack.color = "success";
              dataSnack.showResult = true;
              dataSnack.result = "Set Acknowledge is success.";
            })
            .catch((error) => {
              //console.log(error);
              dataSnack.showResult = true;
              dataSnack.color = "error";
              dataSnack.result = "Error! You cannot set acknowledge.";
            });
        } catch (error) {
          dataSnack.showResult = true;
          dataSnack.color = "error";
          dataSnack.result = "Error! You cannot set acknowledge.";
        }
      };
      let closeFn = function () {
        dataEdit.status = false;
        try {
          //console.log(this.id);
          this.$store
            .dispatch("setAcknowledge", dataEdit)
            .then((res) => {
              if (res.status == 400) {
                dataSnack.showResult = true;
                dataSnack.color = "error";
                dataSnack.result = res.data.error.message;
              } else {
                //console.log("ddd");
                dataSnack.color = "success";
                dataSnack.showResult = true;
                dataSnack.result = "Set Acknowledge is success.";
              }
            })
            .catch((error) => {
              //console.log(error);
              dataSnack.showResult = true;
              dataSnack.color = "error";
              dataSnack.result = "Error! You cannot set acknowledge.";
            });
        } catch (error) {
          dataSnack.showResult = true;
          dataSnack.color = "error";
          dataSnack.result = "Error! You cannot set acknowledge.";
        }
      };
      let data = {
        text: "acknowledge",
        status: this.getBuilding.acknowledge,
      };
      let obj = {
        message: this.checkOnoff(data),
        type: "warning",
        customClass: "custom-simplert",
        useConfirmBtn: true,
        customConfirmBtnText: "ON",
        customCloseBtnText: "OFF",
        customConfirmBtnClass:
          "v-btn v-btn--has-bg theme--light v-size--default success mx-4 same",
        customCloseBtnClass:
          "v-btn v-btn--has-bg theme--light v-size--default error same",
        onConfirm: confirmFn,
        onClose: closeFn,
        showXclose: true,
      };
      this.$Simplert.open(obj);
    },
    setReset() {
      let dataSnack = this.dataSnack;
      let dataEdit = this.dataEdit;
      dataEdit.id = Cookies.get("idBuliding");
      let confirmFn = function () {
        dataEdit.status = true;
        try {
          this.$store
            .dispatch("setReset", dataEdit)
            .then((res) => {
              //console.log(res);
              if (res.status == 400) {
                dataSnack.showResult = true;
                dataSnack.color = "error";
                dataSnack.result = res.data.error.message;
              } else {
                dataSnack.color = "success";
                dataSnack.showResult = true;
                dataSnack.result = "Set Reset is success.";
              }
            })
            .catch((error) => {
              //console.log(error);
              dataSnack.showResult = true;
              dataSnack.color = "error";
              dataSnack.result = "Error! You cannot set reset.";
            });
        } catch (error) {
          dataSnack.showResult = true;
          dataSnack.color = "error";
          dataSnack.result = "Error! You cannot set reset.";
        }
      };
      let closeFn = function () {
        dataEdit.status = false;
        try {
          //console.log(this.id);
          this.$store
            .dispatch("setReset", dataEdit)
            .then((res) => {
              if (res.status == 400) {
                dataSnack.showResult = true;
                dataSnack.color = "error";
                dataSnack.result = res.data.error.message;
              } else {
                //console.log("ddd");
                dataSnack.color = "success";
                dataSnack.showResult = true;
                dataSnack.result = "Set Reset is success.";
              }
            })
            .catch((error) => {
              //console.log(error);
              dataSnack.showResult = true;
              dataSnack.color = "error";
              dataSnack.result = "Error! You cannot set reset.";
            });
        } catch (error) {
          dataSnack.showResult = true;
          dataSnack.color = "error";
          dataSnack.result = "Error! You cannot set reset.";
        }
      };
      let data = {
        text: "reset",
        status: this.getBuilding.reset,
      };
      let obj = {
        message: this.checkOnoff(data),
        type: "warning",
        customClass: "custom-simplert",
        useConfirmBtn: true,
        customConfirmBtnText: "ON",
        customCloseBtnText: "OFF",
        customConfirmBtnClass:
          "v-btn v-btn--has-bg theme--light v-size--default success mx-4 same",
        customCloseBtnClass:
          "v-btn v-btn--has-bg theme--light v-size--default error same",
        onConfirm: confirmFn,
        onClose: closeFn,
        showXclose: true,
      };
      this.$Simplert.open(obj);
    },

    checkOnoff(data) {
      //console.log(data);
      let status;
      let text;
      if (data.status == false) {
        status =
          '<span style="font-size: 150%; font-weight: bold;" >Status Off </span> <br/> ';
      } else {
        status =
          '<span style="font-size: 150%; font-weight: bold;" >Status On </span> <br/> ';
      }
      if (data.text == "reset") {
        text =
          '<span style="font-size: 150%; font-weight: bold;">You want to set reset?</span>';
      } else if (data.text == "acknowledge") {
        text =
          '<span style="font-size: 150%; font-weight: bold;">You want to set acknowledge?</span>';
      } else {
        text =
          '<span style="font-size: 150%; font-weight: bold;">You want to set silence?</span>';
      }
      return status + text;
    },
    clear() {
      let dataSnack = this.dataSnack;
      this.dataSearch.id = Cookies.get("idBuliding");
      let dataSearch = this.dataSearch;
      let confirmFn = function () {
        try {
          //console.log(dataSearch.id);
          this.$store.dispatch("setClear", dataSearch.id).then((res) => {
            if (res.status == 400) {
              dataSnack.showResult = true;
              dataSnack.color = "error";
              dataSnack.result = res.data.error.message;
            } else {
              dataSnack.color = "success";
              dataSnack.showResult = true;
              dataSnack.result = "Clear is success.";
              dataSearch.type = "All";
              this.$store.dispatch("getEvents", dataSearch);
              this.$store.dispatch("getAllEvents");
            }
          });
        } catch (error) {
          dataSnack.showResult = true;
          dataSnack.color = "error";
          dataSnack.result = "Error! You cannot clear event.";
        }
      };
      let obj = {
        message:
          '<span style="font-size: 150%; font-weight: bold;">You want to clear events?</span>',
        type: "warning",
        customClass: "custom-simplert",
        useConfirmBtn: true,
        customConfirmBtnText: "Yes",
        customCloseBtnText: "Cancel",
        customConfirmBtnClass:
          "v-btn v-btn--has-bg theme--light v-size--default primary mx-4 same",
        customCloseBtnClass:
          "v-btn v-btn--has-bg theme--light v-size--default error same",
        onConfirm: confirmFn,
      };
      this.$Simplert.open(obj);
    },
    onResize() {
      if (window.innerWidth < 960) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    loadPage: async function () {
      this.dataSearch.id = Cookies.get("idBuliding");
      this.dataSearch.type = "All";
      //this.name = Cookies.get("nameBuliding");
      this.id = Cookies.get("idBuliding");
      await this.$store.dispatch("getEvents", this.dataSearch);
      this.$store.dispatch("getReportBySearch", this.dataSearch);
      this.$store.dispatch("getBuilding", this.id).then((res) => {
        this.name = res.buildingName;
      });
    },
    searchTypeEvent() {
      //console.log(this.dataSearch);
      this.dataSearch.id = Cookies.get("idBuliding");
      if (this.datesEvent != "") {
        if (this.datesEvent.length === 2) {
          const [yearstart, monthstart, daystart] =
            this.datesEvent[0].split("-");
          const [yearend, monthend, dayend] = this.datesEvent[1].split("-");
          const data = [this.datesEvent[0], this.datesEvent[1]];
          if (yearstart > yearend) {
            this.datesEvent[0] = data[1];
            this.datesEvent[1] = data[0];
          } else if (monthstart > monthend) {
            this.datesEvent[0] = data[1];
            this.datesEvent[1] = data[0];
          } else if (daystart > dayend) {
            this.datesEvent[0] = data[1];
            this.datesEvent[1] = data[0];
          }

          this.dataSearch.eventStart = this.parseDate(this.datesEvent[0]);
          this.dataSearch.eventEnd = this.parseDate(this.datesEvent[1]);
          this.$store
            .dispatch("getEvents", this.dataSearch)
            .then(() => {
              this.color = "success";
              this.text = "Search successfully.";
              this.snackbar = true;
              this.loading = false;
            })
            .catch((error) => {
              ////console.log(error);
              this.snackbar = true;
              this.color = "error";
              this.text = "Error! You cannot search.";
            });
        } else {
          this.snackbar = true;
          this.color = "error";
          this.text = "Error! Please choose a End Time.";
        }
      } else {
        this.$store
          .dispatch("getEvents", this.dataSearch)
          .then(() => {
            this.color = "success";
            this.text = "Search reports successfully.";
            this.snackbar = true;
            this.loading = false;
          })
          .catch((error) => {
            //console.log(error);
            this.snackbar = true;
            this.color = "error";
            this.text = "Error! You cannot search reports.";
          });
      }
    },
    searchTypeHis() {
      //console.log(this.dataSearch);
      this.dataSearch.id = Cookies.get("idBuliding");
      if (this.datesHis != "") {
        if (this.datesHis.length === 2) {
          const [yearstart, monthstart, daystart] =
            this.datesHis[0].split("-");
          const [yearend, monthend, dayend] = this.datesHis[1].split("-");
          const data = [this.datesHis[0], this.datesHis[1]];
          if (yearstart > yearend) {
            this.datesHis[0] = data[1];
            this.datesHis[1] = data[0];
          } else if (monthstart > monthend) {
            this.datesHis[0] = data[1];
            this.datesHis[1] = data[0];
          } else if (daystart > dayend) {
            this.datesHis[0] = data[1];
            this.datesHis[1] = data[0];
          }

          this.dataSearch.eventStart = this.parseDate(this.datesHis[0]);
          this.dataSearch.eventEnd = this.parseDate(this.datesHis[1]);
          this.$store
            .dispatch("getReportBySearch", this.dataSearch)
            .then(() => {
              this.color = "success";
              this.text = "Search successfully.";
              this.snackbar = true;
              this.loading = false;
            })
            .catch((error) => {
              ////console.log(error);
              this.snackbar = true;
              this.color = "error";
              this.text = "Error! You cannot search.";
            });
        } else {
          this.snackbar = true;
          this.color = "error";
          this.text = "Error! Please choose a End Time.";
        }
      } else {
        this.$store
          .dispatch("getReportBySearch", this.dataSearch)
          .then(() => {
            this.color = "success";
            this.text = "Search reports successfully.";
            this.snackbar = true;
            this.loading = false;
          })
          .catch((error) => {
            //console.log(error);
            this.snackbar = true;
            this.color = "error";
            this.text = "Error! You cannot search reports.";
          });
      }
    },
    formatDate(dates) {
      if (!dates) return null;
      const dateFormatted = [];
      for (let i = 0; i < dates.length; i++) {
        dateFormatted[i] = this.parseDate(dates[i]);
      }
      if (dateFormatted.length === 2) {
        const [yearstart, monthstart, daystart] = dateFormatted[0].split("/");
        const [yearend, monthend, dayend] = dateFormatted[1].split("/");
        const data = [dateFormatted[0], dateFormatted[1]];
        if (yearstart > yearend) {
          dateFormatted[0] = data[1];
          dateFormatted[1] = data[0];
        } else if (monthstart > monthend) {
          dateFormatted[0] = data[1];
          dateFormatted[1] = data[0];
        } else if (daystart > dayend) {
          dateFormatted[0] = data[1];
          dateFormatted[1] = data[0];
        }
      }
      return dateFormatted.join(" - ");
    },
    parseDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    showColor(index) {
      //console.log(index);
      if (index > 9) {
        index = index % 10;
      }
      return {
        color: config.colors[index],
      };
    },
    showBorder(type) {
      //console.log(type);
      const index = this.getEvents.type.findIndex((item) => item.name === type);
      //console.log(index)
      return {
        borderLeft: "8px solid",
        borderLeftColor: config.colors[index],
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "5px",
      };
    },
    sumhistorys() {
      this.dataSearch.type = "";
      this.history = true;
    },
    sumevents() {
      this.dataSearch.type = "";
      this.history = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#card {
  border-top: 5px solid;
  border-top-color: #d32f2f;
}
</style>
