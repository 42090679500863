<template>
  <div v-resize="onResize">
    <v-container fluid>
      <v-breadcrumbs :items="itemsRouter">
        <template v-slot:divider>
          <v-icon size="25">mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :disabled="item.disabled" :href="item.href">
            <span :style="`color: ${item.color}`">
              {{ item.text.toUpperCase() }}
            </span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-card class="mx-6">
        <v-card-title>
          <v-row align="center">
            <v-col cols="12" lg="5" md="3" sm="3"> Report </v-col>
            <v-col align="end">
              <v-row align="center">
                <v-col cols="12" lg="3" md="4" sm="4">
                  <v-select
                    :items="getName"
                    item-value="id"
                    item-text="buildingName"
                    label="Select Plant"
                    v-model="datasearch.id"
                    color="#D32F2F"
                    item-color="#D32F2F"
                    v-if="getAccess.role === 'Superadmin'"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" lg="5" md="5" sm="6">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="dates"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    color="#D32F2F"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedDateFormatted"
                        label="Select Date range"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        color="#D32F2F"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dates"
                      range
                      color="#D32F2F"
                      scrollable
                      no-title
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="#D32F2F" @click="cancel()">
                        Cancel
                      </v-btn>
                      <v-btn text color="#D32F2F" @click="save(dates)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col align="end" cols="12" lg="2" md="2" sm="2">
                  <v-tooltip bottom v-if="!isMobile">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="#D32F2F"
                        fab
                        small
                        dark
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="searchDate()"
                      >
                        <v-icon> mdi-magnify </v-icon>
                      </v-btn>
                    </template>
                    <span>Search</span>
                  </v-tooltip>
                  <v-btn
                    v-else
                    color="#D32F2F"
                    fab
                    small
                    dark
                    class="mr-2"
                    @click="searchDate()"
                  >
                    <v-icon> mdi-magnify </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="getReports"
          :items-per-page="5"
          :loading="loading"
          loading-text="Loading... Please wait"
        >
        </v-data-table>

        <div
          v-if="isMobile"
          class="
            table-footer-prepend-mobile
            d-flex
            pl-2
            align-center
            justify-center
          "
        >
          <v-btn color="error">
            <download-excel
              :data="getReports"
              :fields="columns"
              type="excel"
              name="report_firealarm.xls"
            >
              Download Excel
            </download-excel>
          </v-btn>
        </div>
        <div v-else class="table-footer-prepend d-flex pl-2 align-center">
          <v-btn color="error">
            <download-excel
              :data="getReports"
              :fields="columns"
              type="excel"
              name="report_firealarm.xls"
            >
              Download Excel
            </download-excel>
          </v-btn>
        </div>
      </v-card>
      <v-snackbar v-model="snackbar" buttom left :color="color" timeout="1500">
        {{ text }}
        <v-btn color="white" text @click="snackbar = false"> close </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
    getReports() {
      //console.log(this.$store.getters.reportsBuliding);
      return this.$store.getters.reportsBuliding;
    },
    getName() {
      let newFirstElement = { buildingName: "All", id: null };
      let array = this.$store.getters.namesBuilding;
      let newArray = [newFirstElement].concat(array); // [ 4, 3, 2, 1 ]
      ////console.log(newArray);
      return newArray;
    },
    computedDateFormatted() {
      return this.formatDate(this.dates);
    },
    getReportBySearch() {
      return this.$store.getters.reportBuliding;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/");
    }
    this.loadReports();
    const isInStandaloneMode = () =>
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone ||
      document.referrer.includes("android-app://");
    if (isInStandaloneMode()) {
      ////console.log("webapp is installed");
      this.isApp = true;
    }
  },
  data() {
    return {
      loading: false,
      isMobile: false,
      isApp: false,
      snackbar: false,
      text: "",
      color: "",
      menu: false,
      dialog: false,
      dates: [],
      datasearch: {
        id: null,
        type: "",
        eventStart: "",
        eventEnd: "",
      },
      itemsRouter: [
        {
          text: "Dashboard",
          disabled: false,
          color: "red",
          href: "/dashboard",
        },
        {
          text: "Reports",
          disabled: true,
          href: "/reports",
        },
      ],
      columns: {
        Date: "dateStart",
        Time: "timeStart",
        Plant: "building.buildingName",
        Type: "type",
        Description: "description",
      },
      headers: [
        {
          text: "Date",
          align: "start",
          value: "dateStart",
          width: "10%",
        },
        { text: "Time", value: "timeStart", width: "8%" },
        { text: "Plant", value: "building.buildingName", width: "10%" },
        { text: "Type", value: "type", width: "15%" },
        { text: "Device", value: "deviceNo", width: "15%" },
        { text: "Description", value: "description", width: "50%" },
      ],
    };
  },
  methods: {
    onResize() {
      if (window.innerWidth < 700) this.isMobile = true;
      else this.isMobile = false;
    },
    save(dates) {
      if (dates.length == 1) {
        this.snackbar = true;
        this.color = "error";
        this.text = "Error! Please choose a End Time.";
      } else {
        this.$refs.menu.save(dates);
      }
    },
    loadReports() {
      /* this.$store.dispatch("getReports").then(() =>{
        this.loading = false;
      }); */
      this.$store.dispatch("getNames");
    },
    cancel() {
      this.menu = false;
      this.dates = null;
    },
    searchDate() {
      this.loading = true;
      if (this.getAccess.role == "Admin") {
        this.datasearch.id = this.getAccess.building;
      }
      if (this.dates != "") {
        if (this.dates.length === 2) {
          const [yearstart, monthstart, daystart] = this.dates[0].split("-");
          const [yearend, monthend, dayend] = this.dates[1].split("-");
          const data = [this.dates[0], this.dates[1]];
          if (yearstart > yearend) {
            this.dates[0] = data[1];
            this.dates[1] = data[0];
          } else if (monthstart > monthend) {
            this.dates[0] = data[1];
            this.dates[1] = data[0];
          } else if (daystart > dayend) {
            this.dates[0] = data[1];
            this.dates[1] = data[0];
          }
          this.datasearch.eventStart = this.parseDate(this.dates[0]);
          this.datasearch.eventEnd = this.parseDate(this.dates[1]);
          this.$store
            .dispatch("getReportBySearch", this.datasearch)
            .then(() => {
              this.color = "success";
              this.text = "Search reports successfully.";
              this.snackbar = true;
              this.loading = false;
            })
            .catch((error) => {
              ////console.log(error);
              this.snackbar = true;
              this.color = "error";
              this.text = "Error! You cannot search reports.";
            });
        } else {
          this.snackbar = true;
          this.color = "error";
          this.text = "Error! Please choose a End Time.";
        }
      } else {
        this.$store
          .dispatch("getReportBySearch", this.datasearch)
          .then(() => {
            this.color = "success";
            this.text = "Search reports successfully.";
            this.snackbar = true;
            this.loading = false;
          })
          .catch((error) => {
            //console.log(error);
            this.snackbar = true;
            this.color = "error";
            this.text = "Error! You cannot search reports.";
          });
      }
    },
    formatDate(dates) {
      if (!dates) return null;
      const dateFormatted = [];
      for (let i = 0; i < dates.length; i++) {
        dateFormatted[i] = this.parseDate(dates[i]);
      }
      if (dateFormatted.length === 2) {
        const [yearstart, monthstart, daystart] = dateFormatted[0].split("/");
        const [yearend, monthend, dayend] = dateFormatted[1].split("/");
        const data = [dateFormatted[0], dateFormatted[1]];
        if (yearstart > yearend) {
          dateFormatted[0] = data[1];
          dateFormatted[1] = data[0];
        } else if (monthstart > monthend) {
          dateFormatted[0] = data[1];
          dateFormatted[1] = data[0];
        } else if (daystart > dayend) {
          dateFormatted[0] = data[1];
          dateFormatted[1] = data[0];
        }
      }
      return dateFormatted.join(" - ");
    },
    parseDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
  },
};
</script>
<style>
.table-footer-prepend {
  margin-top: -58px;
  height: 58px;
}
.table-footer-prepend-mobile {
  margin-top: -20px;
  height: 116px;
}
</style>
